<template>
  <div id="app">
    <h1>Connect TON Wallet</h1>
    <div id="connect-button">
      <button @click="connectToWallet">Connect Wallet</button>
    </div>
    <div v-if="walletInfo" class="wallet-info">
      <span>Wallet Address: {{ walletInfo.address }}</span>
      <span>Wallet Balance: {{ walletInfo.balance }} TON</span>
    </div>
  </div>
</template>

<script>
import { TonConnectUI } from '@tonconnect/ui';

export default {
  name: 'App',
  data() {
    return {
      walletInfo: null,
      tonConnectUI: null,
    };
  },
  async mounted() {
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: window.location.origin + "/tonconnect-manifest.json",
    });
  },
  methods: {
    async connectToWallet() {
      try {
        await this.tonConnectUI.openSingleWalletModal('telegram-wallet');
        //await this.tonConnectUI.openWalletModal();
        /*const wallet = this.tonConnectUI.getConnectedWallet();
        this.walletInfo = {
          address: wallet.account.address,
          balance: await this.getTonBalance(wallet.account.address),
        };*/
      } catch (error) {
        console.error("Failed to connect wallet", error);
      }
    }
  }
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
}

.wallet-info {
  margin-top: 20px;
}

.wallet-info span {
  display: block;
  margin: 10px 0;
}
</style>
